import React, { useState } from 'react';
import styled from "styled-components";

// Assets
import ContactImg1 from "../../assets/img/c1.png";
import ContactImg2 from "../../assets/img/c2.png";
import ContactImg3 from "../../assets/img/c3.png";

const Contact = () => {
  const [formData, setFormData] = useState({
    fname: '',
    email: '',
    subject: '',
    message: ''
  }) 

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    fetch('https://colitely.com/website-backend/api-v1/send_message.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => response.json())
    .then(data => {
      console.log(data.message);
      // Optionally, you can handle success/failure messages here
      setIsLoading(false);
      setMessage(data.message);
      setFormData({
        fname: '',
        email: '',
        subject: '',
        message: ''
      });
    })
    .catch(error => {
      setIsLoading(false);
      setMessage('Error sending message');
      console.error('Error:', error);
   });

  };




return (
  <Wrapper id="contact">
    <div className="lightBg">
      <div className="container">
        <HeaderInfo>
          <h1 className="font40 extraBold">Let's get in touch</h1>
          <p className="font13">
          Let's Connect and Bring Your Vision to Life! Contact Us Today. 
          <br />
          We will be happy to assist you with any questions.
      
          </p>
        </HeaderInfo>
        <div className="row" style={{ paddingBottom: "30px" }}>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          {message && <div className='notification'>{message}</div>}
            <Form onSubmit={handleSubmit}>
              <label className="font13">First name:</label>
              <input type="text" id="fname" name="fname" value={formData.fname} onChange={handleChange} className="font20 extraBold" />
              <label className="font13">Email:</label>
              <input type="text" id="email" name="email" value={formData.email} onChange={handleChange} className="font20 extraBold" />
              <label className="font13">Subject:</label>
              <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} className="font20 extraBold" />
              <textarea rows="4" cols="50" type="text" id="message" name="message" value={formData.message} onChange={handleChange} className="font20 extraBold" />
              <div className='sep'></div>

              <SumbitWrapper className="flex">
              <button type="submit" className="pointer animate radius8 MsgBtn" disabled={isLoading}>{isLoading ? 'Sending...' : 'Send Message'}</button>
            </SumbitWrapper>
            
            </Form>
        
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
            <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
              <ContactImgBox>
                <img src={ContactImg1} alt="office" className="radius6" />
              </ContactImgBox>
              <ContactImgBox>
                <img src={ContactImg2} alt="office" className="radius6" />
              </ContactImgBox>
            </div>
            <div style={{ width: "50%" }}>
              <div style={{ marginTop: "100px" }}>
                <img src={ContactImg3} alt="office" className="radius6" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
) 
};

export default Contact;

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input[type=text],
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 500;
  color: #fff;
  font-size:18px;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









