import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets

import LogoIcon from "../../assets/png/logo-footer.png";
import InvoiceIcon from "../../assets/img/Billing-Software-by-Refrens.png";

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
            <img src={LogoIcon} alt="" className="logo-footer" />
           </Link>
            <StyleP className="whiteColor font13">
              <div className="email-info">Email: info@colitely.com</div>
              © {getCurrentYear()} - <span className="purpleColor font13">Colitely</span> All Right Reserved
                <div style={{ marginTop: "20px", textAlign: "center" }}>
                    <a href="https://www.refrens.com/free-online-invoicing-software" target="_blank" rel="noopener noreferrer" style={{ display: "block" }}>
                        <img width={150} src={InvoiceIcon} alt="Invoice" />
                    </a>
                </div>
            </StyleP>

            <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
              Back to top
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
 text-align:center;
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
