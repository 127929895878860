import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 45 45" {...props}>
      <g data-name="Group 73">
        <path
          data-name="Path 125"
          d="M4 9C4 7.89543 4.89543 7 6 7H19L24 13H42C43.1046 13 44 13.8954 44 15V39C44 40.1046 43.1046 41 42 41H6C4.89543 41 4 40.1046 4 39V9Z"
          fill="#2F88FF" stroke="#000000"  stroke-width="3"
        />
        <path
          data-name="Path 126"
          // fill="#000000"
          // d="M26 32H34"
        />
      </g>
      
    </svg>

    
  );
}

export default SvgComponent;
